.sign-in-item a
{
  text-decoration: none;
}
 /* Style the message box */
 .message-box {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: center;
}

.full-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: .8;
}
.brand-spinner {
  margin: 50vh auto;
  height: 60px;
  width: 60px;
  -webkit-animation: spin 1.4s infinite linear;
          animation: spin 1.4s infinite linear;
  border: 6px solid #76b900;
  border-right-color: transparent;
  border-radius: 50%;
}

@media (max-width: 2560px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin:20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;
;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: 700 28px/125% "NVIDIA Sans", sans-serif;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: #ffffff;
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: #76b900;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 11px/140% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  -webkit-text-decoration-color: #76B900;
          text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: #76b900;
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: #000000;
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: 700 14px/125% "NVIDIA Sans",
    sans-serif
  ;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}
@media (max-width: 1360px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 300px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: 700 28px/125% "NVIDIA Sans", sans-serif;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: #ffffff;
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: #76b900;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 11px/140% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  -webkit-text-decoration-color: #76B900;
          text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: #76b900;
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: #000000;
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: 700 14px/125% "NVIDIA Sans",
    sans-serif
  ;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}

@media (max-width: 1024px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
    margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: 700 28px/125% "NVIDIA Sans", sans-serif;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: #ffffff;
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: #76b900;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 11px/140% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  -webkit-text-decoration-color: #76B900;
          text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: #76b900;
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: #000000;
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: 700 14px/125% "NVIDIA Sans",
    sans-serif
  ;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}
@media (max-width: 650px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  

  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: 700 28px/125% "NVIDIA Sans", sans-serif;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: #ffffff;
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: #76b900;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 11px/140% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  -webkit-text-decoration-color: #76B900;
          text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: #76b900;
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: #000000;
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: 700 14px/125% "NVIDIA Sans",
    sans-serif
  ;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}


  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
    margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 300px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: 700 28px/125% "NVIDIA Sans", sans-serif;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: #ffffff;
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: #76b900;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 11px/140% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: #1a1a1a;
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  -webkit-text-decoration-color: #76B900;
          text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: #76b900;
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: #000000;
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: 700 14px/125% "NVIDIA Sans",
    sans-serif
  ;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMsg{
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    white-space: normal;
    -webkit-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-break: normal;
    color: #F03232;
}



/* autoprefixer grid: on */
@font-face {
  font-family: NVIDIA;
  src: url(/static/media/NVIDIASans_W_Lt.932a52ed.woff) format("woff"), url(/static/media/NVIDIASans_W_Lt.69bfe953.woff2) format("woff2");
  font-weight: 300;
  font-style: normal; }

html,
body {
  background-color: #fff;
  font-family: NVIDIA;
  margin: 0;
  font-size: 1em;
  font-weight: 300;
  font-style: normal; }

input[type='text'],
select {
  width: 15em;
  background-color: #fff;
  padding: 0.5em;
  border: none;
  border-bottom: 2px solid #e6e6e6;
  transition: all 0.5s ease; }
  input[type='text']:required:hover,
  select:required:hover {
    border-bottom-color: #76b900; }
  input[type='text']:required:focus,
  select:required:focus {
    border-bottom-color: #76b900; }
  input[type='text']:focus,
  select:focus {
    outline: none;
    background-color: #f2f2f2;
    border-bottom: 2px solid #76b900; }
  input[type='text']:hover,
  select:hover {
    background-color: #f2f2f2;
    border-bottom: 2px solid #4f7c00; }

input[type='text']:read-only, input[type='text']:disabled {
  background-color: #cccccc; }
  input[type='text']:read-only:hover, input[type='text']:disabled:hover {
    cursor: not-allowed; }

input[type='text']:disabled {
  background-color: #cccccc; }
  input[type='text']:disabled:hover {
    cursor: not-allowed; }

label.required:before {
  content: '* ';
  color: #d40000; }

select {
  box-sizing: content-box; }
  select:hover {
    cursor: pointer; }

.css-12jo7m5 {
  font-size: 60% !important; }

.css-yr65ji-control {
  box-shadow: 0 0 0px !important;
  outline: 0 !important;
  border-width: 0 0 2px !important;
  border-color: #e6e6e6 !important; }

.textCenter {
  text-align: center; }

.leftMargin {
  margin-left: 2em; }

.mulSelectStyles {
  display: inline-flex;
  align-items: baseline; }

@media (max-width: 1000px) {
  .mulSelectStyles {
    display: block; } }

.css-2613qy-menu {
  margin-bottom: 200px !important; }

.css-1f43avz-a11yText-A11yText {
  border-color: #76b900; }

.css-1pahdxg-control {
  border-top: 1px solid #76b900 !important;
  border-bottom: 1px solid #76b900 !important;
  border-left: 1px solid #76b900 !important;
  border-right: 1px solid #76b900 !important;
  box-shadow: 0 0 0 0 !important; }

.css-1pahdxg-control:hover {
  border-top: 1px solid #76b900 !important;
  border-bottom: 1px solid #76b900 !important;
  border-left: 1px solid #76b900 !important;
  border-right: 1px solid #76b900 !important;
  box-shadow: 2 2 2 2 !important;
  box-shadow: #76b900 !important; }

.TopBarMargin {
  margin-top: 60px !important; }

.css-2b097c-container {
  width: 210px;
  margin-left: 7px;
  height: 10px; }

.css-yk16xz-control {
  border-width: 0px 0px 2px 0px !important;
  /* border-bottom-width: 2px; */
  border-bottom-style: solid !important;
  border-bottom-color: white !important; }

.isvgpuClass {
  display: inline-grid !important; }

.css-5lgh9l-control {
  border-bottom: 2px solid #e6e6e6 !important;
  box-shadow: 2 2 2 2 !important;
  box-shadow: #76b900 !important; }

.submit-content {
  margin: 20px;
  text-align: center; }
  .submit-content > h2 {
    font-weight: 100;
    margin-bottom: 2em;
    display: inline-block; }
  .submit-content .ui.buttons {
    justify-content: center; }
    .submit-content .ui.buttons > .ui.button {
      padding: 1em 2.5em;
      margin-left: 1em;
      box-shadow: 0px 4px 6px #ccc;
      transition: all 0.5s; }
      .submit-content .ui.buttons > .ui.button:hover {
        cursor: pointer; }
    .submit-content .ui.buttons > .ui.button.primary {
      background-color: #76b900;
      color: #fff;
      border: 1px solid #4f7c00; }
      .submit-content .ui.buttons > .ui.button.primary:hover {
        background-color: #4f7c00; }
      .submit-content .ui.buttons > .ui.button.primary:disabled {
        background-color: #cccccc;
        cursor: default;
        box-shadow: none;
        border: 1px solid #cccccc; }
    .submit-content .ui.buttons > .ui.button.neutral {
      background-color: #e6e6e6;
      color: #333;
      border: 1px solid #333; }
      .submit-content .ui.buttons > .ui.button.neutral:hover {
        background-color: #f2f2f2; }

.centered {
  max-width: 1290px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto; }

.error-content {
  display: flex;
  justify-content: center; }
  .error-content > h2 {
    margin-top: 3em;
    display: inline-block;
    font-weight: 100; }

header.AppHeader {
  position: relative;
  height: 18.25em;
  padding: 0;
  margin: 0;
  text-align: right; }
  header.AppHeader .AppBackground {
    background-size: contain;
    background: url(/static/media/particles4.7f2c867a.jpg) top right no-repeat;
    margin-left: 30px; }
  header.AppHeader .AppLogoContainer {
    background-color: #fff;
    text-align: left; }
    header.AppHeader .AppLogoContainer .AppLogo {
      height: 3em;
      padding: 0;
      margin: 0; }
  header.AppHeader h1 {
    text-align: left;
    height: 6em;
    line-height: 6em;
    font-family: "NVIDIA";
    color: #76b900;
    font-size: 2.5em;
    margin: 0;
    margin-left: 0;
    font-weight: 100; }

a {
  color: #76b900;
  transition: all 0.25s ease; }
  a:hover {
    color: #86d300; }

div.hr {
  height: 0;
  border-bottom: 2px solid #e6e6e6;
  margin: 0em; }

.sectionHeader h3 {
  margin: 1em 0;
  color: #76b900;
  font-family: "NVIDIA";
  font-size: 1.85em;
  font-weight: 100; }

.sectionInfo {
  -webkit-transform: translate(11em, -3.7em);
          transform: translate(11em, -3.7em); }

.sectionInfo2 {
  -webkit-transform: translate(23em, -3.7em);
          transform: translate(23em, -3.7em); }

.sectionInfo,
.sectionInfo2 {
  position: absolute;
  line-height: 1.85em;
  font-size: 0.95em;
  color: #4f7c00;
  font-style: italic; }

.leftColumn {
  width: 500px;
  display: inline-block;
  text-align: right; }

.leftColumnOffset {
  width: 526px;
  display: inline-block;
  text-align: right; }

.infoBlock {
  border-top: 1px solid gray;
  text-align: center;
  background-color: #666;
  color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  font-size: 0.9em; }
  .infoBlock p {
    margin: 0;
    padding: 0.5em; }

.infoBlockEval {
  text-align: left !important;
  margin-left: 2em; }
  .infoBlockEval li {
    margin: 0;
    padding: 0.5em; }

footer {
  margin-top: 3em;
  border-top: 1px solid #999;
  color: #000;
  font-size: 12px;
  text-align: center;
  padding: 1em; }

.textInputDiv,
.selectDiv {
  margin: 0.5em 0; }

.orChad {
  position: absolute;
  -webkit-transform: translate(33.5em, -2em);
          transform: translate(33.5em, -2em); }

.helpBlock {
  border: 1px solid #e6e6e6;
  position: absolute;
  -webkit-transform: translate(35em, 0em);
          transform: translate(35em, 0em);
  width: 18em;
  box-shadow: 0px 4px 6px #ccc; }
  .helpBlock .fa-info-circle {
    color: #76b900;
    background-color: #fff;
    font-size: 2em;
    position: absolute;
    -webkit-transform: translate(-0.5em, -0.5em);
            transform: translate(-0.5em, -0.5em); }
  .helpBlock .helpBlockContent {
    margin: 1em;
    font-family: "NVIDIA";
    font-size: 0.9em;
    line-height: 1.4em; }

.actionBar {
  max-width: 1290px;
  padding-left: 100px;
  padding-right: 30px;
  margin: 0 auto; }
  .actionBar .actionBarContent {
    margin-top: 1em;
    display: flex;
    align-items: center; }
  .actionBar button {
    padding: 1em 2.5em;
    margin-left: 1em; }
    .actionBar button:hover {
      cursor: pointer; }
  .actionBar button[type='submit'] {
    background-color: #76b900;
    color: #fff;
    border: 1px solid;
    transition: all 0.5s;
    margin: 0 auto; }
    .actionBar button[type='submit']:disabled {
      background-color: #cccccc;
      cursor: default;
      box-shadow: none;
      border: 1px solid #cccccc; }
  .actionBar button[type='reset'] {
    background-color: #e6e6e6;
    color: #333;
    border: 1px solid #4f7c00; }

.error {
  margin: 1em;
  font-size: 1.5em;
  font-family: "NVIDIA"; }

.AppSection {
  margin: 1em auto;
  padding-left: 100px;
  padding-right: 30px;
  max-width: 1290px; }

.PartnerInfo {
  font-size: .8em; }

.messages {
  position: fixed;
  top: 1em;
  right: 1em;
  background-color: #76b900;
  color: #fff;
  padding: 0;
  border: none;
  box-shadow: 0px 4px 6px #ccc;
  max-width: 50%;
  padding: 0 1.5em 1em 1.5em; }
  .messages > p {
    line-height: 1.2em; }
  .messages .messages-actions {
    text-align: center;
    background-color: #fff;
    margin: 0 -1.5em -1em -1.5em; }
    .messages .messages-actions > button {
      transition: all 0.5s ease-in;
      margin: 1em 0.5em;
      text-transform: uppercase;
      padding: 0.5em 1em;
      background-color: #fff;
      border: 2px solid #4f7c00;
      cursor: pointer;
      font-size: 1.1em; }
      .messages .messages-actions > button:hover {
        background-color: #f2f2f2; }
    .messages .messages-actions > button.hero {
      background-color: #76b900;
      color: #fff; }
      .messages .messages-actions > button.hero:hover {
        background-color: #86d300; }

@media screen and (max-width: 820px) {
  header.AppHeader h1 {
    background-color: rgba(0, 0, 0, 0.7);
    margin-left: 0;
    padding: 1em;
    text-shadow: 2px 2px 0.5em #ececec;
    font-size: 1.75em;
    min-height: 6.5em;
    line-height: 1.75em;
    display: block; }
  .orChad {
    position: relative;
    -webkit-transform: none;
            transform: none;
    margin-bottom: 1em;
    font-style: italic;
    font-size: 0.85em;
    padding-left: 1em; }
  .sectionInfo,
  .sectionInfo2 {
    position: relative;
    -webkit-transform: none;
            transform: none;
    margin: 0 2em 2em 2em; }
  .leftColumn {
    width: auto;
    text-align: left;
    margin: 0 2em; }
  .leftColumnOffset {
    width: auto;
    text-align: left;
    margin: 0 2em; }
  .textInputDiv input {
    display: block; }
  .selectDiv select {
    display: block; }
  input[type='text'],
  select {
    width: auto; }
  .actionBarContent {
    flex-direction: column; }
    .actionBarContent div {
      margin-top: 1em; }
  .actionBar button[type='submit'] {
    margin-top: 1em; }
  .messages {
    position: fixed;
    top: 1em;
    right: 1em;
    left: 1em; }
  .modal {
    font-size: 12px; }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px; }
  .modal > .content {
    width: 100%;
    padding: 10px 5px; }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center; }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece; } }

.MuiButton-root {
  background-color: #76b900 !important;
  color: #fff !important;
  transition: all 0.5s !important; }

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  color: #76b900 !important; }

.css-2613qy-menu {
  border-color: #76b900 !important; }

.css-2613qy-menu:hover {
  border-color: #76b900 !important; }

.css-1wa3eu0-placeholder {
  font-size: 0.83em !important;
  color: black !important;
  margin-left: -4px !important; }

.css-tlfecz-indicatorContainer {
  color: black !important;
  margin-right: -8px !important; }

.css-1okebmr-indicatorSeparator {
  width: 0px !important; }

.css-tj5bde-Svg {
  height: 16px; }

.acceptbtn {
  max-width: 1290px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  margin-right: 35px !important; }
  .acceptbtn button {
    padding: 1em 2.5em;
    margin-left: 1em; }
    .acceptbtn button:hover {
      cursor: pointer; }
  .acceptbtn .otherMargin {
    color: #d40000; }
  .acceptbtn button[type='submit'] {
    background-color: #76b900;
    color: #fff;
    border: 1px solid #4f7c00;
    box-shadow: 0px 4px 6px #ccc;
    transition: all 0.5s;
    margin: 0 auto; }
    .acceptbtn button[type='submit']:hover {
      background-color: #4f7c00; }
    .acceptbtn button[type='submit']:disabled {
      background-color: #cccccc;
      cursor: default;
      box-shadow: none;
      border: 1px solid #cccccc; }

