.sign-in-item a
{
  text-decoration: none;
}
 /* Style the message box */
 .message-box {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: center;
}

.full-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: .8;
}
.brand-spinner {
  margin: 50vh auto;
  height: 60px;
  width: 60px;
  animation: spin 1.4s infinite linear;
  border: 6px solid #76b900;
  border-right-color: transparent;
  border-radius: 50%;
}

@media (max-width: 2560px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin:20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;
;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}
@media (max-width: 1360px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 300px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}

@media (max-width: 1024px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
    margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}
@media (max-width: 650px)
{
  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
  margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  

  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 400px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

}


  .sign-in,
.sign-in * {
  box-sizing: border-box;
}
.sign-in {
  background: #eeeeee;
  width: 100%;
  /* height: 627px; */
  /* position: relative; */
  overflow: hidden;
}

.signbox
{
    margin: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* width: 626px; */
  height: 300px;
}
.login-container {
  text-align: center;
  width: 626px;

}
.login-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-button-container 
{
  display: flex;
  justify-content: space-between; /* This adds space between the two divs */
  margin: 25px; /* Margin on both sides of the container */
  
}
.sign-in-rectangle-13 {
  background: #ffffff;
  width: 626px;
  /* height: 400px; */
  /* flex-direction: row;
  align-items: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-gap: 10px;
  text-align: center;
  /* position: absolute; */
  /* left: 362px;
  top: 164px; */
}
.sign-in-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
 margin-left: 50px; 
 margin-top: -20px;
}
.sign-in-heading-medium {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-medium, 700 28px/125% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.sign-in-frame-41 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  
}
.sign-in-text-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
margin-left: 23px;
  width: 90%;
  
}
.sign-in-text-input2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
 
}
.sign-in-input {
  background: var(--light-theme-background, #ffffff);
  border-style: solid;
  border-color: var(--brand-nvidia-green, #76b900);
  border-width: 2px;
  padding: 10px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 50px;
  /* position: relative; */
  overflow: hidden;
}
.sign-in-input-contents {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* position: relative; */
}
.sign-in-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  /* position: relative; */
}
.sign-in-input-label {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-input-text {
  color: var(--light-theme-text, #1a1a1a);
  text-align: left;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.sign-in-text {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-text-medium {
  color: #000000;
  text-align: left;
  font: 400 15px/166.7% "NVIDIA Sans", sans-serif;
  text-decoration-color: #76B900;
}

.sign-in-rectangle-15 {
  background: #76b900;
  width: 15px;
  height: 65px;
  /* position: absolute; */
 
}
.sign-in-cta-button-primary {
 
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-cta-button-primary2 {
  background: var(--light-theme-hyperlinks-buttons-default, #76b900);
  padding: 9px 13px 9px 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

}
.sign-in-cta-button {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.sign-in-line-6 {
  border-style: solid;
  border-color: #76b900;
  border-width: 1px 0 0 0;
  width: 71px;
  height: 0px;
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMsg{
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    white-space: normal;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-break: normal;
    color: #F03232;
}


