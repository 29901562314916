/* autoprefixer grid: on */

$NvidiaGreen: #76b900;
$NvidiaGreen2: #4f7c00;
$NvidiaGreen3: #004e49;
$backgroundColor: #fff;
$backgroundColor2: darken($backgroundColor, 5);
//$backgroundColor2: #f6f8fa;
$backgroundDisabled: darken($backgroundColor, 20);
$backgroundDarkGray: #454545;
$backgroundDark: #2e2e2e;
$lightBorder: darken($backgroundColor, 10);
$darkBorder: darken($backgroundColor, 50);
$requiredColor: #d40000;
$requiredColorHover: lighten($requiredColor, 20);
$bodyFontSize: 1em;
$NvFont: 'NVIDIA';
$NvidiaGreenHighlight: lighten($NvidiaGreen, 5);
$defaultShadow: 0px 4px 6px #ccc;

@font-face {
  font-family: NVIDIA;
  src: url('../fonts/NVIDIASans_W_Lt.woff') format('woff'),url('../fonts/NVIDIASans_W_Lt.woff2') format('woff2');

  font-weight: 300;
  font-style: normal;
}

html,
body {
  background-color: $backgroundColor;
  font-family: NVIDIA;
  margin: 0;
  font-size: $bodyFontSize;
  font-weight: 300;
  font-style: normal;
}

input[type='text'],
select {
  width: 15em;
  background-color: $backgroundColor;
  padding: 0.5em;
  border: none;
  border-bottom: 2px solid $lightBorder;
  transition: all 0.5s ease;
  &:required {
    //border-bottom-color: $requiredColor;
    &:hover {
      border-bottom-color: #76b900;
    //  border-bottom-color: $requiredColorHover;
    }
   
    &:focus {
      border-bottom-color: #76b900;
      //border-bottom-color: $requiredColor;
    }
  }
  &:focus {
    outline: none;
    background-color: $backgroundColor2;
    border-bottom: 2px solid $NvidiaGreen;
  }
  &:hover {
    background-color: $backgroundColor2;
    border-bottom: 2px solid $NvidiaGreen2;
  }
}

input[type='text'] {
  &:read-only,
  &:disabled {
    background-color: $backgroundDisabled;
    &:hover {
      cursor: not-allowed;
    }
  }
  &:disabled {
    background-color: $backgroundDisabled;
    &:hover {
      cursor: not-allowed;
    }
  }
}

label.required {
  &:before {
    content: '* ';
    color: $requiredColor;
  }
}

select {
  box-sizing: content-box;
  &:hover {
    cursor: pointer;
  }
}
.css-12jo7m5{
  font-size: 60%!important;
}
.css-yr65ji-control{
  box-shadow: 0 0 0px!important;
  outline: 0!important;
  border-width: 0 0 2px!important;
  border-color: #e6e6e6!important
}
.textCenter {
  text-align: center;
}
.leftMargin {
  margin-left: 2em;
}
.mulSelectStyles{
  display: inline-flex;
  align-items: baseline;
}
@media (max-width: 1000px) {

  .mulSelectStyles {
    display: block;
   }

}
.css-2613qy-menu{
  margin-bottom: 200px!important;
}
.css-1f43avz-a11yText-A11yText{
  border-color: #76b900;
}
.css-1pahdxg-control{
  border-top: 1px solid #76b900!important;
    border-bottom: 1px solid #76b900!important;
    border-left: 1px solid #76b900!important;
    border-right: 1px solid #76b900!important;

    box-shadow: 0 0 0 0 !important;
}
.css-1pahdxg-control:hover{
   
  border-top: 1px solid #76b900!important;
  border-bottom: 1px solid #76b900!important;
  border-left: 1px solid #76b900!important;
  border-right: 1px solid #76b900!important;
  box-shadow: 2 2 2 2 !important;   
  
  
  box-shadow: #76b900!important;
}
.TopBarMargin{
margin-top: 60px!important;
}
.css-2b097c-container{
width: 210px;
margin-left: 7px;
height: 10px;
}
.css-yk16xz-control{
  border-width: 0px 0px 2px 0px!important;
    /* border-bottom-width: 2px; */
    border-bottom-style: solid!important;
    border-bottom-color:white!important;
}
.isvgpuClass{
  display: inline-grid!important;
}
.css-5lgh9l-control{
  
  border-bottom: 2px solid #e6e6e6!important;
  
  box-shadow: 2 2 2 2 !important;   
  
  
  box-shadow: #76b900!important;
}
.submit-content {
  margin: 20px;
  text-align: center;

  > h2 {
    font-weight: 100;
    margin-bottom: 2em;
    display: inline-block;
  }

  .ui.buttons {
    justify-content: center;

    > .ui.button {
      padding: 1em 2.5em;
      margin-left: 1em;
      box-shadow: $defaultShadow;
      transition: all 0.5s;
      &:hover {
        cursor: pointer;
      }
    }

    > .ui.button.primary {
      background-color: $NvidiaGreen;
      color: #fff;
      border: 1px solid $NvidiaGreen2;

      &:hover {
        background-color: $NvidiaGreen2;
      }
      &:disabled {
        background-color: $backgroundDisabled;
        cursor: default;
        box-shadow: none;
        border: 1px solid $backgroundDisabled;
      }
    }

    > .ui.button.neutral {
      background-color: $lightBorder;
      color: #333;
      border: 1px solid #333;
      &:hover {
        background-color: lighten($lightBorder, 5);
      }
    }
  }
}

.centered {
  max-width: 1290px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.error-content {
  display: flex;
  justify-content: center;
  > h2 {
    margin-top: 3em;
    display: inline-block;
    font-weight: 100;
  }
}

header.AppHeader {
  position: relative;
  height: 18.25em;
  padding: 0;
  margin: 0;
  text-align: right;

  .AppBackground {
    background-size: contain;
    background: url('../images/particles4.jpg') top right no-repeat;
    margin-left: 30px;
  }
  .AppLogoContainer {
    background-color: #fff;
    text-align: left;

    .AppLogo {
      height: 3em;
      padding: 0;
      margin: 0;
    }
  }
  h1 {
    text-align: left;
    height: 6em;
    line-height: 6em;
    font-family: $NvFont;
    color: $NvidiaGreen;
    font-size: 2.5em;
    margin: 0;
    margin-left: 0;
    font-weight: 100;
  }
}

a {
  color: $NvidiaGreen;
  transition: all 0.25s ease;
  &:hover {
    color: $NvidiaGreenHighlight;
  }
}

div.hr {
  height: 0;
  border-bottom: 2px solid $lightBorder;
  margin: 0em;
}

.sectionHeader {
  h3 {
    margin: 1em 0;
    color: $NvidiaGreen;
    font-family: $NvFont;
    font-size: 1.85em;
    font-weight: 100;
  }
}

.sectionInfo {
  transform: translate(11em, -3.7em);
}
.sectionInfo2 {
  transform: translate(23em, -3.7em);
}
.sectionInfo,
.sectionInfo2 {
  position: absolute;
  line-height: 1.85em;
  font-size: 0.95em;
  color: $NvidiaGreen2;
  font-style: italic;
}

.leftColumn {
  width: 500px;
  display: inline-block;
  text-align: right;
}
.leftColumnOffset {
  width: 526px;
  display: inline-block;
  text-align: right;
}

.infoBlock {
  border-top: 1px solid $darkBorder;
  text-align: center;
  background-color: #666;
  color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  font-size: $bodyFontSize * 0.9;

  p {
    margin: 0;
    padding: 0.5em;
  }
}
.infoBlockEval {
  text-align: left !important;
  margin-left: 2em;
  li {
    margin: 0;
    padding: 0.5em;
  }
}
footer {
  margin-top: 3em;
  border-top: 1px solid #999;
  color: #000;
  font-size: 12px;
  text-align: center;
  padding: 1em;
}

.textInputDiv,
.selectDiv {
  margin: 0.5em 0;
}

.orChad {
  position: absolute;
  transform: translate(33.5em, -2em);
}

.helpBlock {
  border: 1px solid $lightBorder;
  position: absolute;
  transform: translate(35em, 0em);
  width: 18em;
  box-shadow: $defaultShadow;
  .fa-info-circle {
    color: $NvidiaGreen;
    background-color: $backgroundColor;
    font-size: 2em;
    position: absolute;
    transform: translate(-0.5em, -0.5em);
  }
  .helpBlockContent {
    margin: 1em;
    font-family: $NvFont;
    font-size: 0.9em;
    line-height: 1.4em;
  }
}

.actionBar {
  max-width: 1290px;
  padding-left: 100px;
  padding-right: 30px;
  margin: 0 auto;
  .actionBarContent {
    //padding: 0 2em;
    margin-top: 1em;
    display: flex;
    align-items: center;
  }
  button {
    padding: 1em 2.5em;
    margin-left: 1em;
    &:hover {
      cursor: pointer;
    }
  }

  button[type='submit'] {
    background-color: $NvidiaGreen;
    color: #fff;
    border: 1px solid ;
    //border: 1px solid $NvidiaGreen2;
   // box-shadow: $defaultShadow;
    transition: all 0.5s;
    margin: 0 auto;
    &:hover {
   //   background-color: $NvidiaGreen2;
    }
    &:disabled {
      background-color: $backgroundDisabled;
      cursor: default;
      box-shadow: none;
      border: 1px solid $backgroundDisabled;
    }
  }
  button[type='reset'] {
    background-color: $lightBorder;
    color: #333;
    border: 1px solid $NvidiaGreen2;
  }
  // button + button {
  //   margin-left: 1em;
  // }
}

.error {
  margin: 1em;
  font-size: 1.5em;
  font-family: $NvFont;
}

.AppSection {
  margin: 1em auto;
  padding-left: 100px;
  padding-right: 30px;
  max-width: 1290px;
}
.PartnerInfo{
font-size: .8em;
}
.messages {
  position: fixed;
  top: 1em;
  right: 1em;
  background-color: $NvidiaGreen;
  color: #fff;
  padding: 0;
  //border: 0.25em solid $NvidiaGreen;
  border: none;
  box-shadow: $defaultShadow;
  max-width: 50%;
  padding: 0 1.5em 1em 1.5em;

  > p {
    line-height: 1.2em;
    // padding: 0 1.5em 1em 1.5em;
  }

  .messages-actions {
    text-align: center;
    background-color: #fff;
    margin: 0 -1.5em -1em -1.5em;

    > button {
      transition: all 0.5s ease-in;
      margin: 1em 0.5em;
      text-transform: uppercase;
      padding: 0.5em 1em;
      background-color: #fff;
      border: 2px solid $NvidiaGreen2;
      cursor: pointer;
      font-size: 1.1em;
      &:hover {
        background-color: darken(#fff, 5%);
      }
    }
    > button.hero {
      background-color: $NvidiaGreen;
      color: #fff;
      &:hover {
        background-color: lighten($NvidiaGreen, 5%);
      }
    }
  }
}

@media screen and (max-width: 820px) {
  // header.AppHeader {
  //   display: block;
  //   text-align: center;
  // }
  // header.AppHeader .AppLogo {
  //   width: 40%;
  //   height: auto;
  //   margin-bottom: 0;
  // }
  // header.AppHeader h1 {
  //   font-size: 1.75em;
  //   text-decoration: none;
  // }
  header.AppHeader h1 {
    background-color: rgba(0, 0, 0, 0.7);
    margin-left: 0;
    padding: 1em;
    text-shadow: 2px 2px 0.5em rgb(236, 236, 236);
    font-size: 1.75em;
    min-height: 6.5em;
    line-height: 1.75em;
    display: block;
    //min-width: 100%;
  }
  .orChad {
    position: relative;
    transform: none;
    margin-bottom: 1em;
    font-style: italic;
    font-size: 0.85em;
    padding-left: 1em;
  }
  .sectionInfo,
  .sectionInfo2 {
    position: relative;
    transform: none;
    margin: 0 2em 2em 2em;
  }
  .leftColumn {
    width: auto;
    text-align: left;
    margin: 0 2em;
  }
  .leftColumnOffset {
    width: auto;
    text-align: left;
    margin: 0 2em;
  }
  .textInputDiv input {
    display: block;
  }
  .selectDiv select {
    display: block;
  }
  input[type='text'],
  select {
    width: auto;
  }
  .actionBarContent {
    flex-direction: column;
    div {
      margin-top: 1em;
    }
  }
  .actionBar {
    button[type='submit'] {
      margin-top: 1em;
    }
  }

  .messages {
    position: fixed;
    top: 1em;
    right: 1em;
    left: 1em;
  }

  .modal {
    font-size: 12px;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
 
  
}
.MuiButton-root{
  background-color: #76b900!important;
  color: #fff!important;
 
 
  transition: all 0.5s!important;
  
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  color: #76b900!important;
}
.css-2613qy-menu{
  border-color: #76b900!important;
  
  

}
.css-2613qy-menu:hover{
  border-color: #76b900!important;
}

.css-1wa3eu0-placeholder{
  font-size: .83em!important;
  color: black!important;
  margin-left: -4px!important;
}

.css-tlfecz-indicatorContainer{
color: black!important;
margin-right: -8px!important;
}
.css-1okebmr-indicatorSeparator{
  width: 0px!important;
}.css-tj5bde-Svg{
  height: 16px;
}
.acceptbtn {
  max-width: 1290px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  margin-right: 35px!important;

  button {
    padding: 1em 2.5em;
    margin-left: 1em;
    &:hover {
      cursor: pointer;
    }
  }
.otherMargin{
  color: #d40000;
}
  button[type='submit'] {
    background-color: $NvidiaGreen;
    color: #fff;
    border: 1px solid $NvidiaGreen2;
    box-shadow: $defaultShadow;
    transition: all 0.5s;
    margin: 0 auto;
    &:hover {
      background-color: $NvidiaGreen2;
    }
    &:disabled {
      background-color: $backgroundDisabled;
      cursor: default;
      box-shadow: none;
      border: 1px solid $backgroundDisabled;
    }
  }
 
  // button + button {
  //   margin-left: 1em;
  // }
}

